import { Button } from 'primereact/button';
import { Link, Outlet } from "react-router-dom";



const Layout = () => {

  const items = [
    {
        label: 'Home',
        icon: () => <img alt="Home" src="/images/home.png" />,
        to: '/'
    },
    {
        label: 'Blogs',
        icon: () => <img alt="Blogs" src="/images/blogs.png" />,
        to: '/blogs'
    },
    {
        label: 'Contact',
        icon: () => <img alt="Contact" src="/images/contact.png" />,
        to: '/contact'
    },
    {
        label: 'Tech Stack Used',
        icon: () => <img alt="Contact" src="/images/techStack.png" />,
        to: '/techStack'
    },

  ];
  return (
    <>

     <div  style={{ textAlign: 'center'}}>
      <div>
        {items.map((item) => (
          <Link to={item.to} key={item.label} >
            <Button icon={item.icon}
            className="p-button-rounded p-button-secondary"/>
          </Link>
        ))}
      </div>
     </div>
      <Outlet />
    </>
  )
};

export default Layout;
