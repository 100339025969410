import React from 'react';
import styles from '../css/primereact/resources/themes/tailwind-light/theme.module.css';

const TechStack = () => {
  return <h1 className={styles["p-component"]} style={{ textAlign: 'center'}}>
  React JS Version 18.2 <br />
  Type Script Version 5.1.3 <br />
  Prime React Version 9.5 <br/>
  AWS Amplify Version 4.6<br/>
  AWS DynamoDB (NoSQL DB)<br/>
  AWS AppSync for GraphQL Based APIs<br />
  For Prod and Staging hosting: AWS S3 & AWS Cloudfront <br />
  AWS Lambda with JDK 11 for blog creation based on S3 put event<br />
  Prod Site: <a href="https://www.sujithbabu.org">www.sujithbabu.org</a><br />
  Staging Site: <a href="https://www.sujithbabu.org">https://master.d19hns7oyrtmpn.amplifyapp.com/</a><br />
  </h1>;
};

export default TechStack;
