import { DataStore } from '@aws-amplify/datastore';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';
import { useEffect, useState } from 'react';
import styles from '../../css/primereact/resources/themes/tailwind-light/theme.module.css';
import { Post } from '../../models';


const Blogs = () => {
  const [first, setFirst] = useState(0);
  //TODO This should ideally come from a config file or from a config DB table
  const rowsPerPage = 5;
    const onPageChange = (event: PaginatorPageChangeEvent) => {
    // See API https://primereact.org/paginator/#api.Paginator.PaginatorPageChangeEvent
    // for this event details. Unomment this line to understand
    //console.log( event);
    setFirst(event.first);
  };
  const [isLoaded, setIsLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState<String>("");
  const [posts, setPosts] = useState<Post[]>([]);
  const parse = require('html-react-parser');

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const fetchedPosts : Post[] = await DataStore.query(Post);
        setPosts(fetchedPosts);
        setIsLoaded(true);
      } catch (error:any) {
        console.error('An Error fetching posts:', error);
        setIsLoaded(false);
        setErrorMessage(error.message);
      }
    };
    fetchPosts();
  }, []);
  const [isEnglish, setEnglish] = useState(false);
  const toggleLanguage = () =>{
    setEnglish(!isEnglish);
  };
  // Let defaullt be Malayalam
  const headerText="സാധാരണ രീതിയിൽ ഒരാൾ  കാണുവാൻ സാധ്യതയില്ലാത്ത "
    +" സിനിമകൾ ഒരേ പരിചയപ്പെടുത്തുകയാണ് ഈ ബ്ലോഗിലൂടെ "
    +" ഞാൻ ഉദ്ദേശിക്കുന്നത്.ആയതിനാൽ ,"
    +" സിനിമയുടെ കഥ പറയാതിരിക്കുക എന്നുള്ളതാണ് "
    +" പൊതുവേ ചെയ്യാൻ ഉദ്ദേശിക്കുന്നത്."
  const headerTextEnglish="The intention of this blog is to introduce "
    +" some of the good movies that the audience is unlikely to have"
    +" seen earlier. Plot details would be"
    +" avoided to the best I can.";
  if (errorMessage) {
      return <div className={styles["p-component"]}>Try again. Error: {errorMessage} </div>;
  }
  if (!isLoaded) {
      return <div className={styles["p-component"]}>Loading...</div>;
  }


  const renderAccordionItems = () => {
    const endIndex = first + rowsPerPage;
    const visibleData = posts.slice(first, endIndex);
    return visibleData.map(post => (
      <AccordionTab key={post.id} header={post.title} className={styles["p-accordion-content"]}>
      <p className="m-0">
        <img src={post.imageUrl !}
        style={{display: 'block', marginLeft: 'auto', marginRight: 'auto'}}
        alt={post.title !}></img>
          <b>സിനിമ/Cinema: {post.title}&nbsp;ഭാഷ/Language: {post.language}<br/>
          സംവിധായകൻ/Director: {post.director}&nbsp; വർഷം/Year:{post.year}</b><br/>
        </p>
      <p className="m-0">{isEnglish? parse(post.contentEnglish): parse(post.content)}</p>
      </AccordionTab>
    ));
  };

    return (
    <>
        <div>
        <h1 className={styles["p-component"]} style={{textDecorationLine: 'Underline', textAlign: 'center'}}>Film Blogs</h1>
        </div>

        <div className={styles["p-component"]}>
        <p onClick={toggleLanguage} className="m-0" style={{textDecorationLine: 'Underline', textAlign: 'center'}}>മലയാളത്തിൽ നിന്നും ഇംഗ്ലീഷിലേക്കും തിരിച്ചും പോകുന്നത്തിന് ഇവിടെ ക്ലിക്ക് ചെയ്യുക
            <br/>
            Click here to toggle between Malayalam and English
        </p>
        <Accordion activeIndex={0} className={styles["p-accordion"]}>
            <AccordionTab className={styles["p-accordion-header"]} header="എന്തിന് ഈ ബ്ലോഗ്? / Why this blog?"
            key="എന്തിന് ഈ ബ്ലോഗ്? / Why this blog?">
            <p>
                {isEnglish? parse(headerTextEnglish): parse(headerText)}
            </p>
            </AccordionTab>
            {renderAccordionItems()}
        </Accordion>
            <Paginator
            first={first}
            rows={rowsPerPage}
            totalRecords={posts.length}
            onPageChange={onPageChange}/>
        </div>
    </>
    );
  
};

export default Blogs;
